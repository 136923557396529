import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';

import MenuBar from './MenuBar';
import Parallax from './Parallax';


const pageId = 'page';
const duration = 400;


class Layout extends React.Component {
    render() {
        const { children, defaultBanner, pageResources } = this.props;

        return (
        <div id="layout">
            <header>
                <MenuBar/>
            </header>
            <div id="page-spacer">
                <div ref={el => {this._pageEl = el}} className="page"
                     style={{ transition: `opacity ${duration}ms ease-out, transform ${duration}ms ease-out` }}>
                    {children}
                </div>
            </div>
            <footer>
                Copyright © {(new Date()).getFullYear()} Julien Hartmann, all rights reserved.
                <br />
                Code snippets licensed under <a target="_blank" rel="noopener noreferrer" href="http://creativecommons.org/licenses/by/4.0/">CC Attribution 4.0 International License</a>
                {' — '}
                <Link to="/legal">Legal notice</Link>
            </footer>
            <Parallax id="background" ratio={0.5}>
                <img src={defaultBanner.childImageSharp.fluid.src}
                    srcSet={defaultBanner.childImageSharp.fluid.srcSet}
                    sizes="100vw"
                    alt=""/>
            </Parallax>
        </div>
        );
    }

    getSnapshotBeforeUpdate(prevProps) {
        if (prevProps.path !== this.props.path) {
            const page = this._pageEl;
            const state = this.props.location.state;
            return {
                animate: (state && state.animate) || 'default',
                el: this._pageEl.cloneNode(true),
                parent: page.parentElement,
                top: page.offsetTop,
                left: page.offsetLeft,
                width: page.offsetWidth,
                scrollX: (window.scrollX || document.documentElement.scrollLeft),
                scrollY: (window.scrollY || document.documentElement.scrollTop)
            };
        }
        return null;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (snapshot && snapshot.animate && Promise) {
            // Run animation in microtask - and skip if browser does not handle microtasks
            Promise.resolve().then(this.animate.bind(this, snapshot));
        }
    }

    animate(data) {
        const { animate, el, parent, top, left, width, scrollX, scrollY } = data;
        const deltaX = (window.scrollX || document.documentElement.scrollLeft) - scrollX;
        const deltaY = (window.scrollY || document.documentElement.scrollTop) - scrollY;

        const frame = document.createElement('div');
        frame.style.position = 'absolute';
        frame.style.top = '0';
        frame.style.bottom = '0';
        frame.style.left = '0';
        frame.style.right = '0';
        frame.style.overflow = 'hidden';
        frame.style.pointerEvents = 'none';
        frame.setAttribute('role', 'presentation');
        el.style.position = 'absolute';
        el.style.margin = '0';
        el.style.top =  `${top+deltaY}px`;
        el.style.left = `${left+deltaX}px`;
        el.style.width = `${width}px`;
        el.style.opacity = '1';
        el.style.transform = 'translate(0, 0)';
        el.style.boxShadow = 'none';
        frame.appendChild(el);

        setTimeout(() => { parent.removeChild(frame); }, duration);
        parent.appendChild(frame);

        getComputedStyle(el).opacity;   // force recompute

        el.style.opacity = '0';
        switch (animate) {
            case 'previous': el.style.transform = 'translate(2rem, .5rem)'; break;
            case 'next': el.style.transform = 'translate(-2rem, .5rem)'; break;
            default: el.style.transform = 'translate(0, 1rem)';
        }
    }
};

export default props => (
    <StaticQuery query={ graphql`
        query {
            banner: file(relativePath: { eq: "default-banner.jpg" }) {
                childImageSharp {
                    fluid {
                        src
                        srcSet
                    }
                }
            }
        }
    `}
    render={data => <Layout defaultBanner={data.banner} {...props} />}
    />
);
