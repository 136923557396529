import React from 'react';
import { graphql, Link, StaticQuery } from 'gatsby';


const MenuBar = ({ metadata }) => (
    <nav className="menu-bar">
        <div className="brand"><Link to="/">
            <div className="title">{metadata.title}</div>
            <div className="description">{metadata.tagline}</div>
        </Link></div>
        <ul>
            <li><Link to="/" className="menu-item-link" activeClassName="active">Home</Link></li>
            <li><Link to="/resume" className="menu-item-link" activeClassName="active">Résumé</Link></li>
            <li><Link to="/about" className="menu-item-link" activeClassName="active">About</Link></li>
        </ul>
    </nav>
);

export default props => (
    <StaticQuery query={ graphql`
        query {
            site {
                siteMetadata {
                    title
                    tagline
                }
            }
        }
    `}
    render={data => <MenuBar metadata={data.site.siteMetadata} {...props} />}
    />
);
