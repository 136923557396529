import React from 'react';


export default class Parallax extends React.Component {

    constructor(props) {
        super(props);
        this._frameId = null;
        this.updatePosition = this.updatePosition.bind(this);
    }

    render() {
        const { children, ratio, ...props } = this.props;

        return (
            <div ref={el => (this._element = el)} {...props}>
                { children }
            </div>
        );
    }

    componentDidMount() {
        window.addEventListener('scroll', this.updatePosition);
        this.updatePosition();
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.updatePosition);
    }

    // Called straight from scroll event, we can get away with it because reading those
    // properties does not trigger layout, nor does setting a transform.
    // This helps with IE, as rAF-based animation shows jitter due to IE rendering a frame
    // before actually calling the rAF.
    updatePosition() {
        const ratio = this.props.ratio;
        const scrollX = -ratio * (window.scrollX || document.documentElement.scrollLeft);
        const scrollY = -ratio * (window.scrollY || document.documentElement.scrollTop);
        this._element.style.transform = `translate(${scrollX}px, ${scrollY}px)`;
    }
};
